import React, { useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import {
  TopSection,
  Section,
  TopTitleWrapper,
  PageTopThumbnailWrapper,
  StaticThumbnail,
  media,
} from '~/styles';
import Card from '~/components/staff/Card';
import staffThumbnail from '~/assets/images/staff.jpg';
import { injectInterviewLink } from '~/components/interview/helpers';

const StaffPage: React.VFC<PageProps<GatsbyTypes.StaffPageQuery>> = ({
  data: { rawStaffs },
}) => {
  const { i18n } = useTranslation();

  const staffs = useMemo(
    () =>
      rawStaffs.nodes.map((staff) => {
        const staffJa = {
          name: staff.name,
          nameSub: staff.name_sub,
          position: staff.position,
          text: staff.text,
        };

        const staffDataEn =
          staff.i18n_refs && staff.i18n_refs?.length > 0
            ? staff.i18n_refs[0]?.ref
            : null;

        const staffEn = staffDataEn
          ? {
              name: staffDataEn.name,
              nameSub: staffDataEn.name_sub,
              position: staffDataEn.position,
              text: staffDataEn.text,
            }
          : null;

        return {
          slug: staff.slug?.current,
          thumbnail: staff.thumbnail?.asset?.gatsbyImageData,
          hotspot: staff.thumbnail?.hotspot,
          twitterId: staff.twitter_id,
          interview: injectInterviewLink(staff.slug?.current ?? ''),
          localizedItem: i18n.language === 'en' && staffEn ? staffEn : staffJa,
        };
      }),
    [i18n.language, rawStaffs],
  );

  return (
    <>
      <SEO title="Staff" />
      <Layout>
        <TopSection>
          <PageTopThumbnailWrapper>
            <StaticThumbnail
              src={staffThumbnail}
              style={{ objectPosition: '25% top' }}
              alt="thumbnail"
            />
          </PageTopThumbnailWrapper>
          <TopTitleWrapper>
            <TitleWithCatchCopy
              title="staff.title"
              catchCopy="staff.catchCopy"
              catchSub="staff.catchSub"
              byKey
              top
              isIntersection
            />
          </TopTitleWrapper>
        </TopSection>
        <Section>
          <List>
            {staffs.map((staff) => (
              <Card
                position={staff.localizedItem.position ?? ''}
                name={staff.localizedItem.name ?? ''}
                nameSub={staff.localizedItem.nameSub ?? ''}
                text={staff.localizedItem.text ?? ''}
                thumbnail={staff.thumbnail}
                hotspot={staff.hotspot}
                twitterId={staff.twitterId}
                key={staff.slug ?? ''}
                interview={staff.interview}
              />
            ))}
          </List>
        </Section>
      </Layout>
    </>
  );
};

const List = styled.ul`
  display: grid;
  margin: 0;
  padding: 0;

  ${media.mdUp} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 80px;
    align-items: start;
  }

  ${media.mdDown} {
    grid-gap: 60px;
  }
`;

export default StaffPage;

export const query = graphql`
  query StaffPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    rawStaffs: allSanityStaff(
      filter: { i18n_lang: { eq: "ja" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        i18n_refs {
          lang
          ref {
            ... on SanityStaff {
              name
              name_sub
              position
              text
            }
          }
        }
        name
        name_sub
        position
        text
        twitter_id
        thumbnail {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, fit: FILL)
          }
          hotspot {
            x
            y
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
